import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getToken } from "context/auth";

import { ReactComponent as GarageIcon } from 'assets/icons/ico_garage_black.svg';
import { ReactComponent as ElectricityIcon } from 'assets/icons/ico_electricity_black.svg';
import { ReactComponent as FenceIcon } from 'assets/icons/ico_fence_black.svg';
import { ReactComponent as SunIcon } from 'assets/icons/ico_sun.svg';
import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";

import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';

import { useMessages } from "context/messages";
import ItemContextMenu from "../../dashboard/tableItemContextMenu/itemContextMenu";
import { deleteParkingSpots } from "api/locations";

const ParkingSpotItem = ({
                             parkingSpot,
                             parkingSpots,
                             setParkingSpots,
                             setShowEditParkingSpotModal,
                             setParkingSpotToEdit
                         }) => {
    const { t } = useTranslation();
    const [ showMenu, setShowMenu ] = useState();
    const { showToast, showModal, hideModal } = useMessages();
    const [ isDeleting, setIsDeleting ] = useState();

    const handleRemoveParkingSpot = () => {
        setShowMenu(false);

        showModal(
            t('parkingArea.removeParkingSpotTitle'),
            t('parkingArea.removeParkingSpotDescription'),
            t('generic.yes'),
            async () => {
                setIsDeleting(true);
                hideModal();

                try {
                    const token = await getToken();
                    const deleteParkingSpotResult = await deleteParkingSpots(token, [ parkingSpot.parkingSpotExtId ]);

                    if (deleteParkingSpotResult.error) {
                        console.error(deleteParkingSpotResult);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    } else {
                        /* Successful, remove item from list */

                        const index = parkingSpots.findIndex(({ parkingSpotExtId }) => parkingSpotExtId === parkingSpot.parkingSpotExtId);
                        parkingSpots.splice(index, 1);
                        setParkingSpots([ ...parkingSpots ]);

                        showToast(t('parkingArea.removedParkingSpotTitle'), t('parkingArea.removedParkingSpotDescription'), 'success');
                    }
                } catch (error) {
                    console.error(error);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                setIsDeleting(false);
            },
            t('generic.cancel'),
            () => {
                hideModal()
            },
            'warning');
    }

    const handleShowEditParkingSpotModal = () => {
        setShowMenu(false);
        setShowEditParkingSpotModal(true);
        setParkingSpotToEdit(parkingSpot);
    }

    if (!parkingSpot) {
        return (null);
    }

    return (
        <tr className='h-16 border-b-[1px] border-airpark-gray-300'>
            <td className={`pl-6 ${parkingSpot.isUnavailable ? 'opacity-30' : ''}`}><a href="#" onClick={(e) => {
                e.preventDefault();
                handleShowEditParkingSpotModal();
            }}>{parkingSpot.parkingSpotExtId}</a></td>
            <td className={`pl-6 ${parkingSpot.isUnavailable ? 'opacity-30' : ''}`}>{parkingSpot.isNumbered ? t('parkingArea.yes') : t('parkingArea.no')}</td>
            <td className={`pl-6`}>{parkingSpot.floor ?? t('parkingArea.unspecified')}</td>
            <td className={`pl-6 ${parkingSpot.isUnavailable ? 'opacity-30' : ''}`}>{parkingSpot.isNumbered ? parkingSpot.spotIdentifier : t('parkingArea.notNumbered')}</td>
            <td className={`pl-6 ${parkingSpot.isUnavailable ? 'opacity-30' : ''}`}>
                <div className="flex">
                    {parkingSpot.isIndoor &&
                        <GarageIcon className="h-6 w-6" />
                    }
                    {!parkingSpot.isIndoor &&
                        <SunIcon className="h-6 w-6" />
                    }
                    {parkingSpot.hasChargingPost &&
                        <ElectricityIcon className="h-6 w-6 ml-2" />
                    }
                    {parkingSpot.isFenced &&
                        <FenceIcon className="h-6 w-6 ml-2" />
                    }
                </div>
            </td>
            <td className="border-l-[1px] border-airpark-gray-300 min-w-[72px] w-[72px] relative">
                {isDeleting &&
                    <div className="flex h-16 w-full justify-center items-center">
                        <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isDeleting &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => {
                        e.preventDefault();
                        setShowMenu(!showMenu);
                    }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showMenu &&
                    <ItemContextMenu
                        onCloseClick={() => {
                            setShowMenu(false);
                        }}
                        onEditClick={() => {
                            handleShowEditParkingSpotModal();
                        }} />
                    // onDeleteClick={() => { handleRemoveParkingSpot(); }}
                }
            </td>
        </tr>
    );
}

export default ParkingSpotItem;