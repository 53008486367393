import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { getParkingSpots } from "api/locations";
import { createPriceRuleSet, getPriceRuleSetByExtId, updatePriceRuleSet } from "api/priceRuleSets";

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ico_arrow_left.svg';

import ButtonText from "components/common/buttonText";
import InputDate from "components/common/inputDate";
import InputDateAndTime from "components/common/inputDateAndTime/inputDateAndTime";
import ToggleText from "components/common/toggleText";
import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";
import DropdownSelector from "components/common/dropdownSelector/dropdownSelector";
import InputNumber from "components/common/inputNumber";
import InputText from "components/common/inputText/inputText";
import SelectParkingSpotsModal from "components/dashboard/selectParkingSpotsModal";
import ButtonSlider from "components/common/buttonSlider";
import CollapsibleContainer from "components/common/collapsibleContainer";

import { getToken } from "context/auth";
import { useDashboard } from "context/dashboard";
import { useMessages } from "context/messages";

import { format } from "date-fns";
import { getCommunitiesForParkingArea } from "api/communities";
import Alert from "components/common/alert";
import moment from "moment";
import WeeklyTimeslotDisplay from "components/common/weeklyTimeslotDisplay";

const ParkingAreaAddPriceRuleSetPage = () => {

    const navigate = useNavigate();
    const { organization } = useDashboard();
    const { showToast, showBetweenPagesMessage, hideBetweenPagesLoading } = useMessages();
    const { t } = useTranslation();
    const { parkingAreaExtId, priceRuleSetExtId } = useParams();
    const [isLoading, setIsLoading] = useState();
    const [displayAddParkingSpotsModal, setDisplayParkingSpotsModal] = useState();
    const [parkingSpots, setParkingSpots] = useState();
    const [priceRuleSet, setPriceRuleSet] = useState({
        description: '',
        isRecurring: false,
        parkingAreaExtId: parkingAreaExtId,
        price: '',
        unit: 'hour',
        priceRuleSetExtId: '',
        startDateTime: new Date(),
        endDateTime: new Date(new Date().getTime() + (365 * 24 * 60 * 60 * 1000)),
        isSpecificParkingSpots: false,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
        timeFrom: '00:00:00',
        timeTo: '23:59:00',
        isCommunity: false,
        isPayableAheadOfTime: true,
        noEndDate: true,
        isRecurring: false,
        isDaySpan: false
    });
    const [communities, setCommunities] = useState();
    const [limitNumberOfHoursParkingIsAllowed, setLimitNumberOfHoursParkingIsAllowed] = useState();
    const [isSaving, setIsSaving] = useState();
    const [recurringDays, setRecurringDays] = useState([]);
    const [timeRanges, setTimeRanges] = useState([]);

    useEffect(() => {
        const load = async () => {

            setIsLoading(true);

            try {
                const token = await getToken();

                /* Always get parking spots for the parking area */
                const parkingSpotsResult = await getParkingSpots(token, parkingAreaExtId);

                if (!parkingSpotsResult.error) {
                    setParkingSpots(parkingSpotsResult);
                } else {
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                const communitiesResult = await getCommunitiesForParkingArea(token, parkingAreaExtId);

                if (!communitiesResult.error) {
                    setCommunities(communitiesResult);
                } else {
                    console.error(communitiesResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                /* If we don't have a priceRuleSetExtId then we are adding a new price rule set, default to all parking spots */
                if (priceRuleSetExtId) {
                    const priceRuleSetResult = await getPriceRuleSetByExtId(token, priceRuleSetExtId);

                    var endDateTime = new Date(priceRuleSetResult.endDateTime);

                    if (priceRuleSetResult.isRecurring) {
                        endDateTime = getRecurringEndDate(priceRuleSetResult);
                    }

                    if (!priceRuleSetResult.error) {
                        setPriceRuleSet({
                            ...priceRuleSetResult,
                            parkingAreaExtId: parkingAreaExtId,
                            startDateTime: new Date(priceRuleSetResult.startDateTime),
                            endDateTime: endDateTime,
                            recurringEndDateTime: new Date(priceRuleSetResult.endDateTime), //priceRuleSetResult.isDaySpan ? new Date(priceRuleSetResult.endDateTime ?? new Date(new Date().getTime() + (365 * 24 * 60 * 60 * 1000))) : new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)),
                            noEndDate: !priceRuleSetResult.endDateTime
                        });

                        if (priceRuleSetResult.maxParkingTimeInMinutes) {
                            setLimitNumberOfHoursParkingIsAllowed(true);
                        }
                    } else {
                        console.error(priceRuleSetResult);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    }
                } else {
                    setPriceRuleSet({ ...priceRuleSet, parkingSpots: parkingSpotsResult });
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        load();
    }, [parkingAreaExtId, priceRuleSetExtId]);

    const getRecurringEndDate = (priceRuleSet) => {
        if (!priceRuleSet.isDaySpan) {
            const timeOfDayHours = priceRuleSet.timeTo.split(':')[0];
            const timeOfDayMinutes = priceRuleSet.timeTo.split(':')[1];
            var recurringEndDate = moment(priceRuleSet.startDateTime)
                .set({ hour: timeOfDayHours, minute: timeOfDayMinutes })
                .toDate();

            const timeFrom = moment(priceRuleSet.startDateTime)
                .set({ hour: priceRuleSet.timeFrom.split(':')[0], minute: priceRuleSet.timeFrom.split(':')[1] })
                .toDate();

            if (recurringEndDate < timeFrom) {
                // Next day
                recurringEndDate = moment(recurringEndDate).add(1, 'days').toDate();
            }

            return recurringEndDate;
        }
        
        var daysAdded = 0;
        var daysChecked = 0;

        // I want to loop from the start date, check if the day is selected, if it is, add it to the array, if not, skip it
        for (var m = moment(priceRuleSet.startDateTime).set({ hour: 23, minute: 59, second: 0 }); m.diff(moment(priceRuleSet.endDateTime).set({ hour: 23, minute: 59, second: 0 }), 'days') <= 0; m.add(1, 'days')) {
            const day = m.format('dddd').toLowerCase();

            daysChecked++;

            if (priceRuleSet[day]) {
                daysAdded = daysChecked;
            }

            if (daysChecked == 7) {
                // Time to is in the format of HH:mm:ss, we only want the hours and minutes
                const timeOfDayHours = priceRuleSet.timeTo.split(':')[0];
                const timeOfDayMinutes = priceRuleSet.timeTo.split(':')[1];

                const endDate = moment(priceRuleSet.startDateTime).add(daysAdded-1, 'days').set({ hour: timeOfDayHours, minute: timeOfDayMinutes}).toDate();

                return endDate;
            }
        }
    }

    useEffect(() => {
        if (!priceRuleSet) {
            return;
        }

        const isDaySpan = moment(priceRuleSet?.endDateTime).diff(moment(priceRuleSet?.startDateTime), 'minutes') >= 1440;
        const rd = [];
        const selectedWeekdays = { monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false };

        if (priceRuleSet.isRecurring && isDaySpan) {
            for (var m = moment(priceRuleSet.startDateTime).set({ hour: 23, minute: 59, second: 0 }); m.diff(moment(priceRuleSet.endDateTime).set({ hour: 23, minute: 59, second: 0 }), 'days') <= 0; m.add(1, 'days')) {
                const day = m.format('dddd').toLowerCase();
                selectedWeekdays[day] = true;
                rd.push(day);
            }

            setPriceRuleSet({ ...priceRuleSet, ...selectedWeekdays, isDaySpan: true });
            setRecurringDays(rd);
        } else if (priceRuleSet.isRecurring && !isDaySpan) {
            setPriceRuleSet({ ...priceRuleSet, isDaySpan: false });
        }

    }, [priceRuleSet?.startDateTime, priceRuleSet?.endDateTime, priceRuleSet?.isRecurring]);

    const handleBackButton = () => {
        navigate(-1);
    }

    const handleToggleSpecificSpots = () => {
        if (priceRuleSet.isSpecificParkingSpots) {
            setPriceRuleSet({ ...priceRuleSet, isSpecificParkingSpots: !priceRuleSet.isSpecificParkingSpots, parkingSpots: [...parkingSpots] })
        } else {
            setPriceRuleSet({ ...priceRuleSet, isSpecificParkingSpots: !priceRuleSet.isSpecificParkingSpots })
        }
    }
    const handleToggleIsCommunity = () => {
        if (priceRuleSet.isCommunity) {
            setPriceRuleSet({ ...priceRuleSet, isCommunity: !priceRuleSet.isCommunity, communityExtId: '' });
        } else {
            setPriceRuleSet({ ...priceRuleSet, isCommunity: !priceRuleSet.isCommunity, communityExtId: communities?.length > 0 ? communities[0].communityExtId : '' });
        }
    }

    const handleToggleIsRecurring = () => {
        if (priceRuleSet.isRecurring) {
            setPriceRuleSet({ ...priceRuleSet, isRecurring: false, recurringEndDateTime: null, monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true });
        } else {
            setPriceRuleSet({ ...priceRuleSet, isRecurring: true, recurringEndDateTime: new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)) });
        }
    }

    const handleSave = async () => {
        if (isSaving) {
            return;
        }

        if (!priceRuleSet.description) {
            showToast(t('addPriceRuleSet.errorTitle'), t('addPriceRuleSet.errorName'), 'error');
            return;
        }

        if (priceRuleSet.parkingSpots.length < 0) {
            showToast(t('addPriceRuleSet.errorTitle'), t('addPriceRuleSet.errorParkingSpots'), 'error');
            return;
        }

        if (priceRuleSet.endDateTime != null && priceRuleSet.startDateTime > priceRuleSet.endDateTime) {
            showToast(t('addPriceRuleSet.errorTitle'), t('addPriceRuleSet.errorDateRange'), 'error');
            return;
        }

        if (priceRuleSet.price < 1) {
            showToast(t('addPriceRuleSet.errorTitle'), t('addPriceRuleSet.errorPrice'), 'error');
            return;
        }

        setIsSaving(true);

        let endDateTime = priceRuleSet.isRecurring ? priceRuleSet.recurringEndDateTime : priceRuleSet.endDateTime;

        const priceRuleSetPayLoad = {
            ...priceRuleSet,
            startDateTime: `${format(priceRuleSet.startDateTime, 'yyyy-MM-dd')}T${format(priceRuleSet.startDateTime, 'HH:mm')}`,
            endDateTime: `${format(endDateTime, 'yyyy-MM-dd')}T${format(endDateTime, 'HH:mm')}`,
            timeFrom: priceRuleSet.isRecurring ? `${format(priceRuleSet.startDateTime, 'HH:mm')}:00` : '00:00:00',
            timeTo: priceRuleSet.isRecurring ? `${format(priceRuleSet.endDateTime, 'HH:mm')}:00` : '23:59:00'
        };

        try {
            const token = await getToken();

            /* If we have a price rule set ext id we should update this price rule set */
            if (priceRuleSetExtId) {
                const result = await updatePriceRuleSet(token, priceRuleSetPayLoad);

                if (!result.error) {
                    showBetweenPagesMessage(t('addPriceRuleSet.priceRuleSetUpdatedTitle'), t('addPriceRuleSet.priceRuleSetUpdatedDescription', { name: priceRuleSet.description }), () => { navigate(`/${organization.organizationExtId.toLowerCase()}/parking-areas/${parkingAreaExtId}`) }, 'success');
                } else {
                    console.error(result);
                    hideBetweenPagesLoading();
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } else {
                var result = await createPriceRuleSet(token, priceRuleSetPayLoad);

                if (!result.error) {
                    showBetweenPagesMessage(t('addPriceRuleSet.priceRuleSetAddedTitle'), t('addPriceRuleSet.priceRuleSetAddedDescription', { name: priceRuleSet.description }), () => { navigate(`/${organization.organizationExtId.toLowerCase()}/parking-areas/${parkingAreaExtId}`) }, 'success');
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsSaving(false);
    }

    useEffect(() => {
        let timeRanges = [];
        var timeRange = {
            fromTime: moment(priceRuleSet.startDateTime).format('HH:mm'),
            toTime: moment(priceRuleSet.endDateTime).format('HH:mm'),
            fromDate: moment(priceRuleSet.startDateTime).format('yyyy-MM-DD'),
            toDate: moment(priceRuleSet.endDateTime).format('yyyy-MM-DD')
        };

        const moreThanOneWeek = () => {
            return moment(priceRuleSet.endDateTime).diff(moment(priceRuleSet.startDateTime), 'days') >= 7;
        };

        if (priceRuleSet.isDaySpan) {
            const startDay = recurringDays[0];
            const endDay = recurringDays[recurringDays.length - 1];

            timeRanges.push({ ...timeRange, fromDayOfWeek: startDay, toDayOfWeek: endDay, moreThanOneWeek: moreThanOneWeek() });
        } else {
            const timeTo = moment(priceRuleSet.startDateTime)
                .set({ hour: moment(priceRuleSet.endDateTime).format('HH'), minute: moment(priceRuleSet.endDateTime).format('mm') })
                .toDate();

            const timeFrom = moment(priceRuleSet.startDateTime)
                .set({ hour: moment(priceRuleSet.startDateTime).format('HH'), minute: moment(priceRuleSet.startDateTime).format('mm') })
                .toDate();

            if (timeTo < timeFrom) {
                // Next day
                if (priceRuleSet.monday) {
                    timeRanges.push({ ...timeRange, fromDayOfWeek: 'monday', toDayOfWeek: 'tuesday' });
                }

                if (priceRuleSet.tuesday) {
                    timeRanges.push({ ...timeRange, fromDayOfWeek: 'tuesday', toDayOfWeek: 'wednesday' });
                }
                
                if (priceRuleSet.wednesday) {
                    timeRanges.push({ ...timeRange, fromDayOfWeek: 'wednesday', toDayOfWeek: 'thursday' });
                }
                
                if (priceRuleSet.thursday) {
                    timeRanges.push({ ...timeRange, fromDayOfWeek: 'thursday', toDayOfWeek: 'friday' });
                }
                
                if (priceRuleSet.friday) {
                    timeRanges.push({ ...timeRange, fromDayOfWeek: 'friday', toDayOfWeek: 'saturday' });
                }
                
                if (priceRuleSet.saturday) {
                    timeRanges.push({ ...timeRange, fromDayOfWeek: 'saturday', toDayOfWeek: 'sunday' });
                }
                
                if (priceRuleSet.sunday) {
                    timeRanges.push({ ...timeRange, fromDayOfWeek: 'sunday', toDayOfWeek: 'monday' });
                }
            } else {
                if (priceRuleSet.monday) {
                    timeRanges.push({ ...timeRange, fromDayOfWeek: 'monday', toDayOfWeek: 'monday' });
                }

                if (priceRuleSet.tuesday) {
                    timeRanges.push({ ...timeRange, fromDayOfWeek: 'tuesday', toDayOfWeek: 'tuesday' });
                }
                
                if (priceRuleSet.wednesday) {
                    timeRanges.push({ ...timeRange, fromDayOfWeek: 'wednesday', toDayOfWeek: 'wednesday' });
                }
                
                if (priceRuleSet.thursday) {
                    timeRanges.push({ ...timeRange, fromDayOfWeek: 'thursday', toDayOfWeek: 'thursday' });
                }
                
                if (priceRuleSet.friday) {
                    timeRanges.push({ ...timeRange, fromDayOfWeek: 'friday', toDayOfWeek: 'friday' });
                }
                
                if (priceRuleSet.saturday) {
                    timeRanges.push({ ...timeRange, fromDayOfWeek: 'saturday', toDayOfWeek: 'saturday' });
                }
                
                if (priceRuleSet.sunday) {
                    timeRanges.push({ ...timeRange, fromDayOfWeek: 'sunday', toDayOfWeek: 'sunday' });
                }
            }
        }

        setTimeRanges(timeRanges);
    }, [recurringDays, priceRuleSet]);

    const getFriendlyTimeSpan = () => {
        return `${t('addPriceRuleSet.between')} ${moment(priceRuleSet.startDateTime).format('HH:mm')} ${t('addPriceRuleSet.and')} ${moment(priceRuleSet.endDateTime).format('HH:mm')}`;
    }

    return (
        <>
            <button className="flex p-6 absolute top-12 left-12 md:visible invisible" onClick={(e) => { e.preventDefault(); handleBackButton(); }}><ArrowLeftIcon /></button>
            <div className="flex flex-col m-auto max-w-[448px] w-full">
                {!isLoading && priceRuleSet && organization &&
                    <>
                        <h1 className="mt-14 text-center text-2xl font-medium mb-8">{priceRuleSetExtId ? t('addPriceRuleSet.editTitle') : t('addPriceRuleSet.title')}</h1>
                        <InputText
                            tooltip={t('addPriceRuleSet.tooltipDescription')}
                            title={t('addPriceRuleSet.description')}
                            value={priceRuleSet.description}
                            onChange={(value) => { setPriceRuleSet({ ...priceRuleSet, description: value }) }} />
                        <div className="flex flex-col mb-3 mt-3">
                            <ToggleText
                                title={t('addPriceRuleSet.pickParkingSpots')}
                                isToggled={priceRuleSet.isSpecificParkingSpots}
                                defaultText={t('addPriceRuleSet.all')}
                                toggledText={t('addPriceRuleSet.specificSpots')}
                                onClick={() => { handleToggleSpecificSpots(); }} />
                        </div>
                        <CollapsibleContainer isExpanded={priceRuleSet.isSpecificParkingSpots}>
                            {priceRuleSet.isSpecificParkingSpots &&
                                <div className="flex">
                                    <ButtonText className="w-full mb-3" onClick={() => { setDisplayParkingSpotsModal(true); }}>
                                        {t('addPriceRuleSet.selectedNumberOfSpots', { number: priceRuleSet.parkingSpots.length })}
                                    </ButtonText>
                                </div>
                            }
                        </CollapsibleContainer>
                        <div className="flex flex-col mt-3">
                            <div className="flex flex-col">
                                <InputDateAndTime
                                    title={t('addPriceRuleSet.from')}
                                    value={priceRuleSet.startDateTime}
                                    onChange={(date) => { setPriceRuleSet({ ...priceRuleSet, startDateTime: date }) }} />
                            </div>
                            <div className="flex flex-col mt-3">
                                <InputDateAndTime title={t('addPriceRuleSet.to')}
                                    value={priceRuleSet.endDateTime ?? new Date()}
                                    onChange={(date) => { setPriceRuleSet({ ...priceRuleSet, endDateTime: date }) }} />
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-2 mt-3">
                            <ButtonSlider title={t('addPriceRuleSet.recurring')} isToggled={priceRuleSet.isRecurring} onClick={() => {
                                handleToggleIsRecurring();
                            }} />
                            <CollapsibleContainer isExpanded={priceRuleSet.isRecurring}>
                                <div className="flex flex-col mt-4">
                                    <WeeklyTimeslotDisplay timeRanges={timeRanges}/>
                                    {!priceRuleSet.isDaySpan &&
                                        <div className="flex flex-col gap-y-1 mt-1">
                                            <div className="flex justify-between">
                                                <ButtonWeekday
                                                    title={t('dayShortNames.monday')}
                                                    isSelected={priceRuleSet.monday}
                                                    onClick={() => { setPriceRuleSet({ ...priceRuleSet, monday: !priceRuleSet.monday }) }} />
                                                <ButtonWeekday
                                                    title={t('dayShortNames.tuesday')}
                                                    isSelected={priceRuleSet.tuesday}
                                                    onClick={() => { setPriceRuleSet({ ...priceRuleSet, tuesday: !priceRuleSet.tuesday }) }} />
                                                <ButtonWeekday
                                                    title={t('dayShortNames.wednesday')}
                                                    isSelected={priceRuleSet.wednesday}
                                                    onClick={() => { setPriceRuleSet({ ...priceRuleSet, wednesday: !priceRuleSet.wednesday }) }} />
                                                <ButtonWeekday
                                                    title={t('dayShortNames.thursday')}
                                                    isSelected={priceRuleSet.thursday}
                                                    onClick={() => { setPriceRuleSet({ ...priceRuleSet, thursday: !priceRuleSet.thursday }) }} />
                                                <ButtonWeekday
                                                    title={t('dayShortNames.friday')}
                                                    isSelected={priceRuleSet.friday}
                                                    onClick={() => { setPriceRuleSet({ ...priceRuleSet, friday: !priceRuleSet.friday }) }} />
                                                <ButtonWeekday
                                                    title={t('dayShortNames.saturday')}
                                                    isSelected={priceRuleSet.saturday}
                                                    onClick={() => { setPriceRuleSet({ ...priceRuleSet, saturday: !priceRuleSet.saturday }) }} />
                                                <ButtonWeekday
                                                    title={t('dayShortNames.sunday')}
                                                    isSelected={priceRuleSet.sunday}
                                                    onClick={() => { setPriceRuleSet({ ...priceRuleSet, sunday: !priceRuleSet.sunday }) }} />
                                            </div>
                                            <span>{getFriendlyTimeSpan()}</span>
                                        </div>
                                    }
                                    <div className="mt-3">
                                        <InputDate
                                            title={t('addPriceRuleSet.recurringTo')}
                                            value={priceRuleSet.recurringEndDateTime ?? new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000))}
                                            onChange={(value) => { setPriceRuleSet({ ...priceRuleSet, recurringEndDateTime: value }) }} />
                                    </div>
                                </div>
                            </CollapsibleContainer>
                        </div>
                        <hr className="w-full mt-8 mb-8 border-airpark-gray-400" />
                        <div className="flex flex-col mb-4 gap-y-2">
                            <ButtonSlider title={t('addPriceRuleSet.limitNumberOfHoursParkingIsAllowed')} isToggled={limitNumberOfHoursParkingIsAllowed} onClick={() => {
                                if (limitNumberOfHoursParkingIsAllowed) {
                                    setPriceRuleSet({ ...priceRuleSet, maxParkingTimeInMinutes: null });
                                }
                                setLimitNumberOfHoursParkingIsAllowed(!limitNumberOfHoursParkingIsAllowed);
                            }} />
                            <CollapsibleContainer isExpanded={limitNumberOfHoursParkingIsAllowed}>
                                <InputNumber title={t('addPriceRuleSet.maxParkingTimeInHours')} unit='h' value={priceRuleSet.maxParkingTimeInMinutes ? priceRuleSet.maxParkingTimeInMinutes / 60 : ''} onChange={(value) => { setPriceRuleSet({ ...priceRuleSet, maxParkingTimeInMinutes: value * 60 }) }} />
                            </CollapsibleContainer>
                        </div>
                        <div className="flex flex-col mb-4 gap-3">
                            <DropdownSelector
                                title={t('addPriceRuleSet.availableFor')}
                                options={[{ title: t('addPriceRuleSet.public'), value: false }, { title: t('addPriceRuleSet.community'), value: true }]}
                                defaultValue={priceRuleSet.isCommunity}
                                onChange={(value) => { handleToggleIsCommunity(); }} />
                            {priceRuleSet.isCommunity && communities?.length > 0 &&
                                <DropdownSelector
                                    title={t('addPriceRuleSet.chooseCommunity')}
                                    options={communities.map((community) => { return { title: community.name, value: community.communityExtId } })}
                                    onChange={(value) => { setPriceRuleSet({ ...priceRuleSet, communityExtId: value }) }}
                                    defaultValue={priceRuleSet.communityExtId}
                                />
                            }
                            {priceRuleSet.isCommunity && communities.length < 1 &&
                                <Alert
                                    title={t('addPriceRuleSet.noCommunitiesTitle')}
                                    description={t('addPriceRuleSet.noCommunitiesDescription')}
                                    template='warning' />
                            }
                        </div>
                        <div className="flex flex-col mb-4">
                            <DropdownSelector
                                title={t('addPriceRuleSet.price')}
                                options={[
                                    { title: `${organization.currencySymbol}/${t('priceUnit.hour')}`, value: 'hour' },
                                    { title: `${organization.currencySymbol}/${t('priceUnit.day')}`, value: 'day' },
                                    { title: `${organization.currencySymbol}/${t('priceUnit.onetime')}`, value: 'onetime' }]}
                                defaultValue={priceRuleSet.unit}
                                onChange={(value) => { setPriceRuleSet({ ...priceRuleSet, unit: value }) }} />
                            <div className="flex mb-2" />
                            <InputNumber
                                value={priceRuleSet.price}
                                unit={`${organization.currencySymbol}/${t(`priceUnit.${priceRuleSet.unit}`)}`}
                                onChange={(value) => { setPriceRuleSet({ ...priceRuleSet, price: value }) }} />
                        </div>
                        <ButtonText
                            className="mt-4"
                            isLoading={isSaving}
                            disabled={isSaving}
                            onClick={() => { handleSave(); }}>
                            {priceRuleSetExtId ? t('generic.update') : t('generic.save')}
                        </ButtonText>
                    </>
                }
                {isLoading &&
                    <WidgetLoadingSkeleton />
                }
            </div>
            {priceRuleSet && priceRuleSet.parkingSpots &&
                <SelectParkingSpotsModal
                    display={displayAddParkingSpotsModal}
                    parkingSpots={parkingSpots}
                    selectedParkingSpots={priceRuleSet.parkingSpots}
                    setSelectedParkingSpots={(value) => { setPriceRuleSet({ ...priceRuleSet, parkingSpots: value }) }}
                    setDisplay={setDisplayParkingSpotsModal} />
            }
        </>

    );
}

const ButtonWeekday = ({ title, isSelected, onClick }) => {

    const className = isSelected ?
        'flex h-12 w-12 rounded-lg bg-airpark-green hover:bg-airpark-green-400 items-center justify-center text-white' :
        'flex h-12 w-12 rounded-lg border-[1px] border-airpark-gray-400 items-center justify-center hover:border-airpark-gray-500';

    return (
        <button className={className} onClick={() => { onClick(); }}>
            <span>{title}</span>
        </button>
    );
}

export default ParkingAreaAddPriceRuleSetPage;